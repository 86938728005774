#frontend_div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
}
#call_top_tap {
    border-radius: 0 6px 0 0;
}
#call_left_container {
    width: 100%;
    height: 100%;
    margin-left: 5%;
}
.call_main {
    font-size: 2.2vw !important;
}
.call_sub {
    font-size: 1.3vw !important;
}
.spinner>div {
    width: 0.5vw !important;
    height: 0.5vw !important;
}
#busy_spinner {
    margin-bottom: 0.3vw;
    margin-left: 5px;
}

.patient-area {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction:row;
    justify-content: center;
    background: white;
    border-radius: 6px 6px 0px 0px;
}
