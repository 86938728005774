.item_root {

    /* width: 15% !important; */
    height: 70%;
    margin: 1vw;;
    align-items: center;
    text-transform: capitalize;
    position: relative;
}
.item_sleep {
    /* display: flex; */
    /* width: 15% !important; */
    height: 70%;
    margin: 1vw;;
    align-items: center;
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
}
.patient_area {
    width: 100%;
    height: 100%;
}
.camera_area {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
    justify-content: center;
    border-radius: 5px;
}
@keyframes blink { 
    50% { border-color: #ff0000; } 
}
.animate {
    animation: blink .5s step-end infinite alternate;
}
.stop {
    animation: blink .0s step-end infinite alternate;
}
.patient_img {
    display: flex;
    width: 100%;
    height: 100%;
}
.patient_name {
    font-size: max(0.93vw, 12px);
    background-color:#1C1D1E;
    color:white;
    position:absolute;
    bottom:0;
    left:0;
    border-radius:5px;
    padding:3px;
    display:flex;
    align-items:center;
    /* fontSize:' max(0.93vw, 12px)',
    margin: 5px 0;
    width: 100%;
    font-family: Poppins; */
}
.doctor_img {
    width: 25%;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.blue_circle{
    width: 3vw;
    height: 3vw;
    background: #007cbb;
    margin: 10px;
    border-radius: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.other_div {
    display: flex;
    width: 40%;
    position: absolute;
    top: 0px;
    right: 30px;
    background: #aaaaaabb;
    justify-content: center;
    z-index: 1;
}
.other_div .hi_btn {
    font-weight: bold;
    font-size: max(0.7vw, 10px);
    color: white;
    text-transform: initial;
    font-family: Poppins;
    padding: 0px;
    outline: 0px!important;
}
.call_img {
    color: #FFFFFF;
    font-size: 2vw!important;
}
.show {
    visibility: visible;
}
.hide {
    visibility: hidden;
}
.over_area {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.over_container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.over_opacity_area {
    position: absolute;
    width: 100%;
    height: 100%;
    background: red;
    opacity: 0.3;
}
.hold_lab {
    color: white;
    font-size: max(1.6vh, 12px);
    text-shadow: 2px 2px 7px rgb(236 9 9 / 80%);
    z-index: 1;
    font-family: SemiBold;
    margin: 0px;
    text-transform: initial;
}
.hold_call_img {
    width: 21%;
    z-index: 1;
}
.hold_info_area {
    display: flex;
    margin-left: 8px;
    flex-direction: column;
}
.hold_time_area {
    display: flex;
    flex-direction: row;
}
.calltag_div {
    position: absolute;
    display: flex;
    max-width: 100%;
    bottom: 20px;
    justify-content: center;
    background: #e00000;
    /* left: -2px; */
    z-index: 1;
}
.calltag_lab {
    color: white;
    font-size: max(0.625vw,10px);
    display: flex;
    align-items: center;
    margin: 1px 14px;
    font-family: Poppins;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.item_full_screen_btn {
    width: 30px;
    height: 30px;
    min-width: auto!important;
    min-height: auto!important;
    outline: 0px!important;
    padding: 0px!important;
    position: absolute!important;
    bottom: 0;
    right: 0;
}
.item_full_screen_btn .MuiButton-label {
    background: #ffffff26;
    border-radius: inherit;
    height: 100%;
}
.item_logo {
    width: 65%;
}

.item_relaod_location_btn {
    min-width: auto!important;
    min-height: auto!important;
    outline: 0px!important;
    padding: 3px !important;
    position: absolute!important;
    top: 0;
    right: 0;
    /* background-color: #1C1D1E !important; */
    color:white !important;
    border-radius: 5px !important;


}

.item_relaod_location_btn .MuiButton-label {
    background: #666666;
    border-radius: inherit;
    /* height: 100%; */
}